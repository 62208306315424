/*
|--------------------------------------------------------------------------
| CRUD operations to manage resources with the backend - composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different composable files with the CRUD operations
| to manage the resources with the backend.
*/

// Import Vue.js Framework functions
//import { ref } from 'vue'
//import { toRefs } from 'vue'
//import { reactive } from 'vue'

// Import librairies
import axios from 'axios'

// Export function with contains the exosed properties and methods
export default function useCrudComp() {

/*
    const state = reactive({
        resources: null,
    })
*/


    function getAllResources(data) {
        return axios({
            method: 'get',
            url: data.url,
        })
    /*
        .then((res) => {
            state.resources = res.data.data
        })
    */
    }

    function searchResources(data) {
        return axios({
            method: 'get',
            url: data.url,
        })
    }

    function getResourcesFromDateInterval(data) {
        return axios({
            method: 'post',
            url: data.url,
            data: data.resource
        })
    }

    function storeResource(data) {

        return axios({
            method: 'post',
            url: data.url,
            data: data.resource
        })
    /*
        .then((res) => {
            state.resources.push(res.data.resource)
        })
    */
    }

    function showResource(data) {
        return axios({
            method: 'get',
            url: data.url,
            data: data.id
        })
    }

    function updateResource(data) {
        return axios({
            method: 'put',
            url: data.url,
            data: data.resource
        })
    }

    function deleteResource(data) {
        return axios({
            method: 'delete',
            url: data.url,
            data: data.id
        })
    }

    function deleteResourcesFromGroupId(data) {
        return axios({
            method: 'delete',
            url: data.url,
            data: data.id
        })
    }

    return {
        //...toRefs(state),
        //resources,
        getAllResources,
        searchResources,
        getResourcesFromDateInterval,
        storeResource,
        showResource,
        updateResource,
        deleteResource,
        deleteResourcesFromGroupId
    }
}
